exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interior-design-service-aizawl-mizoram-jsx": () => import("./../../../src/pages/interior-design-service-aizawl-mizoram.jsx" /* webpackChunkName: "component---src-pages-interior-design-service-aizawl-mizoram-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-shop-interior-hardware-accessories-jsx": () => import("./../../../src/pages/shop-interior-hardware-accessories.jsx" /* webpackChunkName: "component---src-pages-shop-interior-hardware-accessories-jsx" */)
}

